import { VFC } from 'react'
import styled from 'styled-components'
import { YougoBoxProps, YougoDefinitionListProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'
import { AnchorAdjuster } from './Utils'

export const YougoDefinitionList: VFC<YougoDefinitionListProps> = ({ yougoList }) => (
  <StyledYougoDefinitionList>
    {yougoList.map(yougo => (
      <YougoBox key={yougo.id}>
        <dt>
          <AnchorAdjuster id={yougo.id} />
          {yougo.title}
        </dt>
        <dd>{yougo.description}</dd>
      </YougoBox>
    ))}
  </StyledYougoDefinitionList>
)

const StyledYougoDefinitionList = styled.dl``

const YougoBox = styled.div<Partial<YougoBoxProps>>`
  background-color: ${colorConfig.bgSubColor};
  padding: 0.3rem;

  > dt {
    background-color: ${colorConfig.bgColor};
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
    color: ${colorConfig.themeColor};
  }

  > dd {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
  }

  & + & {
    margin-top: 2.4rem;
  }
`
